<template>
    <LiefengContent>
        <template v-slot:title>“一社区一档案”服务情况</template>
        <template v-slot:contentArea>
            <!--用户画像-->
            <Card class="protarit-div">
                <div class="portrait-title">{{ "“一社区一档案”服务情况" }}</div>
                <div class="pie-image">
                    <!--性别分布-->
                    <div class="search-div" style="display: flex; margin: 20px 0">
                        <DatePicker v-model="yearOfMonth" type="month" transfer placeholder="请选择日期" style="width: 200px" format="yyyy-MM" @on-change="changeDate" />
                        <Button style="margin-left: 20px" type="primary" @click="getStatIntroduceByDate()">查询</Button>
                        <Button style="margin-left: 20px" type="info" @click="resetBtn">重置</Button>

                        <div style="margin-left: auto; margin-right: 20px">
                            <Button type="primary" @click="updateRow">更新数据</Button>
                        </div>
                    </div>
                    <div class="title-padding" @click="openModal">{{ "服务总数:" + totals }}</div>
                    <div class="pie-content">
                        <div class="image">
                            <Vepie :colors="colors" :data="StatIntroduceByData"></Vepie>
                        </div>
                        <div class="table">
                            <Table stripe :columns="sexPeopleColumn" :data="sexPeopleData"></Table>
                        </div>
                    </div>
                </div>
            </Card>
        </template>
    </LiefengContent>
</template>

<script>
// VeLine:折线图  VePie:饼图  VeHistogram:柱状图
import LiefengContent from "@/components/LiefengContent3"
import VeBar from "v-charts/lib/bar.common"
import Ring from "v-charts/lib/ring.common"
import Vepie from "v-charts/lib/pie.common"
export default {
    components: {
        LiefengContent,
        VeBar,
        Ring,
        Vepie,
    },
    data() {
        return {
            sexPeopleColumn: [
                {
                    type: "index",
                    width: 100,
                    align: "center",
                    title: "序号",
                },
                {
                    title: "服务类型",
                    key: "服务类型",
                    align: "center",
                    minWidth: 100,
                },
                {
                    title: "服务次数",
                    key: "服务次数",
                    align: "center",
                    minWidth: 100,
                },
                {
                    title: "占比",
                    key: "per",
                    align: "center",
                    minWidth: 100,
                },
            ],
            sexPeopleData: [],
            // 柱状图的样式
            hisExtend: {
                series: {
                    barMaxWidth: 50,
                },
            },
            // 机构引入社区累计用户对比
            StatIntroduceByData: {
                columns: ["服务类型", "服务次数"],
                rows: [],
            },
            selectList: [],
            colors: ["#633FEB", "#6497FE", "#38C882", "#FF9645", "#FB3C04", "#41AD99", "#A03FEB"],
            totals: 0,
            showCascader: true,
            cascaderList: [],
            resetNum: 1, // 是否清空当前社区选择
        }
    },
    methods: {
        // 更新数据按钮
        updateRow() {
            this.$Message.loading({
                content: "正在更新，请稍等",
            })
            this.$get("/gateway/api/symanage/pc/o2omin/add").then(res => {
                this.$Message.destroy()
                if (res && res.code == 200) {
                    this.$Message.success({
                        content: "更新成功",
                        background: true,
                    })
                    this.getStatIntroduceByDate()
                } else {
                    this.$Message.error({
                        content: "更新失败",
                        background: true,
                    })
                }
            })
        },
        // 修改时间
        changeDate(val) {
            this.yearOfMonth = this.$core.formatDate(new Date(val), "yyyy-MM")
        },
        // 重置
        resetBtn() {
            this.resetNum++
            this.cascaderList = []
            this.yearOfMonth = ""
            this.getStatIntroduceByDate()
        },
        changeParams(obj) {
            // 点击时传递的参数处理，先处理循环对象，再将json字符串的双引号转为单引号。
            var cache = []
            var objs = JSON.stringify(obj, function (key, value) {
                if (typeof value === "object" && value !== null) {
                    if (cache.indexOf(value) !== -1) {
                        return
                    }
                    cache.push(value)
                }
                return value
            }).replace(/\"/g, "'")
            return objs
            // Enable garbage collection
        },
        openModal() {
            if(this.totals == 0) return
            let params = {
                row: {
                    communityCode: parent.vue.loginInfo.userinfo.communityCode,
                    type:'org',
                    month:this.yearOfMonth
                },
            }
            if(this.sexPeopleData && this.sexPeopleData.length){
                this.sexPeopleData.map(item =>{
                    console.log(item);
                    if(item.key && item.key != ''){
                        params.row[item.key] = item.服务次数
                    }
                })
            }
            this.$core.openLayerFrame({
                type: 2,
                title: "服务明细",
                content: `/page#/situationdetail?obj=${this.changeParams(params.row)}`,
                area: ["100%", "100%"],
            })
        },
        // 获取机构引入社区累计用户数对比
        getStatIntroduceByDate() {
            this.$get("/gateway/api/symanage/pc/o2omin/getByZone", {
                month: this.yearOfMonth && this.yearOfMonth != "" ? this.$core.formatDate(new Date(this.yearOfMonth), "yyyy-MM") : "",
                orgCode: parent.vue.loginInfo.userinfo.communityCode,
            }).then(res => {
                this.StatIntroduceByData = {
                    columns: ["服务类型", "服务次数"],
                    rows: [],
                }
                this.totals = 0
                var value = {}
                this.sexPeopleData = []
                if (res && res.data && JSON.stringify(res.data) != "{}") {
                    this.selectList.map(item => {
                        for (var key in item) {
                            if (key == "dictKey") {
                                value[item[key]] = item["dictValue"]
                            }
                        }
                    })
                    for (var key in res.data) {
                        this.StatIntroduceByData.rows.push({ 服务类型: value[key], 服务次数: res.data[key], key })
                    }
                    this.StatIntroduceByData.rows.map((item, index) => {
                        this.totals = this.totals + Number(item["服务次数"])
                    })
                    this.StatIntroduceByData.rows.map(item => {
                        item.per = Math.round((Number(item["服务次数"]) / this.totals) * 100) + "%"
                    })
                    this.sexPeopleData = [...this.StatIntroduceByData.rows, { 服务类型: "合计", 服务次数: this.totals }]
                }
            })
        },
        async getDictTye() {
            await this.$get("/dictlist/api/lfcommon/pc/dict/selectDictDataList", {
                appCode: "SUIYUE",
                dictType: "O2O_SERVICE_TYPE",
            }).then(res => {
                if (res.code == 200) {
                    this.selectList = res.dataList
                }
            })
        },
    },
    async created() {
        await this.getDictTye()
        await this.getStatIntroduceByDate()
    },
}
</script>

<style lang="less" scoped>
.title-div {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    .title-left {
        width: 80%;
        display: flex;
        flex-flow: wrap;
        .item-num {
            width: 150px;
            height: 150px;
            margin-right: 10px;
            text-align: center;
            .num {
                padding-top: 50px;
                padding-bottom: 40px;
                font-size: 30px;
                font-weight: bold;
            }
            .title {
                font-size: 16px;
            }
        }
    }
    .title-center {
        width: 10%;
        .item-num {
            margin: 0 auto;
            width: 150px;
            height: 150px;
            margin-right: 10px;
            text-align: center;
            .num-red {
                font-size: 30px;
                padding-top: 50px;
                padding-bottom: 40px;
                font-weight: bold;
                color: red;
            }
            .title {
                font-size: 16px;
            }
        }
    }
    .title-right {
        width: 10%;
        .item-num {
            margin: 0 auto;
            width: 150px;
            height: 150px;
            margin-right: 10px;
            text-align: center;
            .num-weight {
                padding-top: 50px;
                padding-bottom: 40px;
                font-weight: bold;
                font-size: 40px;
            }
            .title {
                font-size: 16px;
            }
        }
    }
}
.protarit-div {
    width: 100%;
    margin-top: 20px;
    .portrait-title {
        text-align: center;
        font-weight: bold;
        font-size: 26px;
        border-bottom: 1px solid #ccc;
        margin: 0 auto;
        padding-bottom: 10px;
    }
    .pie-image {
        border-bottom: 1px solid #ccc;
        font-size: 16px;
        .title-padding {
            padding: 10px 0;
            // font-weight: bold;
            font-size: 16px;
            color: #0fb26c;
            text-decoration: underline;
            padding-left: 60%;
            font-size: 24px;
        }
        .pie-content {
            display: flex;
            justify-content: space-between;
            .image {
                width: 60%;
            }
            .table {
                width: 40%;
            }
        }
    }
    .people-num {
        .title-padding {
            font-weight: bold;
            font-size: 16px;
            padding: 10px 0;
        }
    }
    .link-div {
        width: 100%;
        height: 400px;
        padding: 10px 0;
        .select-div {
            display: flex;
            justify-content: flex-start;
        }
    }
    .protrait-table {
        padding: 10px 0;
    }
    // 饼图css
    .jus-div {
        width: 100%;
    }
}
.content {
    margin-top: 120px;
    .link-div {
        .select-div {
            display: flex;
            justify-content: flex-start;
        }
    }
}
.table-div {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .table-left,
    .table-right {
        width: 49.5%;
        min-height: 699px;
        overflow: scroll;
        .update-time {
            margin-bottom: 5px;
            font-size: 16px;
            display: flex;
            justify-content: space-between;
            text-align: center;
            .table-title {
                padding-left: 53px;
                font-weight: bold;
            }
            .table-btn {
                color: #169bd5;
                cursor: pointer;
                user-select: none;
            }
        }
    }
}
</style>